import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { omit } from 'lodash-es'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { addYears, format, isWithinInterval, sub } from 'date-fns'
import CurrencyInput from 'react-currency-input-field'

import ConsumerDisclosureStatementModal from '../../modals/ConsumerDisclosureStatementModal'
import FraudDisclosureModal from '../../modals/FraudDisclosureModal'
import ViaffinityDisclosureContent from '../../ViaffinityDisclosureContent'
import {
  formFields,
  INSURANCE_QUOTE_STEPS,
  selectCurrentBuildingStructure,
  selectInsuranceQuoteInterstitialState
} from 'components/insurance/get_quote/slice/slice'
import { BIG_NUMBER, formattedNumber } from 'components/insurance/get_quote/utils'
import { useCopyOverridesFor } from 'components/insurance/get_quote/slice/overridesSlice'
import { useBindInjectedElements } from 'components/insurance/get_quote/hooks'
import { useWatchableRef } from 'lib/hooks'

const VALID_PHONE_NUMBER_REGEX =
  /^(?:\+\d{1,2}\s?)?(?:\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}(?:\s?(?:ext|extension)\s?\d{1,5})?$/

const USStates = Object.keys(Constants.US_States).filter((key) => Constants.US_States[key].country === 'US')

export default function AboutYouForm({ onBack, onNext, formData, formOptions }) {
  const [consumerDisclosureStatementModalVisibility, setConsumerDisclosureStatementModalVisibility] = useState(false)
  const [fraudDisclosureModalVisibility, setFraudDisclosureModalVisibility] = useState(false)
  const containerRef = useWatchableRef(null)

  const { errorDescription, formErrors, insuranceType, startingQuote } = useSelector(selectInsuranceQuoteInterstitialState)
  const buildingStructure = useSelector(selectCurrentBuildingStructure)
  const { overrides } = useCopyOverridesFor(insuranceType, startingQuote?.writingCompany)

  const { insuredTypes, occupations, carrierNames } = formOptions

  const occupationsHash = useMemo(() => {
    return occupations.reduce((acc, occupation) => {
      acc[occupation.type] = occupation.details
      return acc
    }, {})
  }, [occupations])

  const {
    register,
    handleSubmit,
    resetField,
    getValues,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      number_of_mortgages: 0,
      prior_carrier_name: 0,
      occupation_type: 'Agriculture',
      occupation_detail: 'Administrative Assistant',
      ...formData,
      insured_type: insuranceType == 'renters' ? 'individual' : '',
      opt_in: false,
    },
    mode: 'onBlur',
  })

  const handleClick = (event, action, target) => {
    if (action === 'open-modal') {
      $(`#${target}`).modal('show')
    }
  }

  useBindInjectedElements(overrides, containerRef, handleClick)

  useEffect(() => {
    const aboutYouFormFields = formFields[INSURANCE_QUOTE_STEPS.about_you_page].filter(k => !formFields[INSURANCE_QUOTE_STEPS.policy_page].includes(k))
    Object.keys(formErrors).filter(key => aboutYouFormFields.includes(key)).forEach(k => {
      setError(k, { type: 'custom', message: typeof(formErrors[k]) === "Array" ? formErrors[k].join(', ') : formErrors[k] })
    })
  }, [formErrors])

  const occupationTypeWatch = watch('occupation_type')
  const isBillingAddressSame = watch('is_billing_address_same_as_location')
  const isOptedIn = watch('opt_in', false)
  const selectRequiredValidation = (message) => (value) => {
    return !value ? message : true
  }

  const onConsumerDisclosureStatementModalClose = useCallback(() => {
    setConsumerDisclosureStatementModalVisibility(false)
  }, [setConsumerDisclosureStatementModalVisibility])

  const onFraudDisclosureModalClose = useCallback(() => {
    setFraudDisclosureModalVisibility(false)
  }, [setFraudDisclosureModalVisibility])

  const isValidAge = (value) => {
    let valueCheck
    if (typeof value === 'string') {
      valueCheck = new Date(value)
    } else if (value instanceof Date) {
      valueCheck = value
    }
    return !isWithinInterval(valueCheck, { start: addYears(new Date(), -18), end: new Date() })
  }

  const goBack = () => {
    const formDataToBeSaved = { ...formData, ...getValues() }
    onBack(formDataToBeSaved)
  }

  const onSubmit = (data) => {
    let formDataToBeSaved = { ...data, ...getValues(), modifiedAt: (new Date()).toISOString() }
    if (isBillingAddressSame) {
      formDataToBeSaved = omit(formDataToBeSaved, [
        'billing_address_line1',
        'billing_address_line2',
        'billing_city',
        'billing_state',
        'billing_zip',
      ])
    }
    onNext(formDataToBeSaved)
  }

  const renderErrorsFromServer = () => {
    return (
      <>
        <div className="p3 dome-color-red-alert">{errorDescription}</div>
      </>
    )
  }

  const isOccupationHomemaker = getValues('occupation_type') === 'Homemaker'

  return (
    <>
      <div ref={containerRef.ref} className="about-you-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="about-you-content-wrapper">
            <div className="about-you-form-content-section">
              <div className="about-you-description">
                <div>
                  <i className="fa-light fa-sparkle" style={{ color: '#A8CB8F' }}></i>
                </div>
                <div>
                  <p className="p2">finalize quote</p>
                  <p className="p3">We just need to know a little about you first.</p>
                </div>
              </div>
              <div className="about-you-form-content-item">
                <div className="dome-h4 dome-color-dark-grey">contact info</div>
                <div className="about-you-form-group-wrapper">
                  <div className="about-you-form-group">
                    <label htmlFor="about-you_first_name" className="about-you-form-label">
                      first name*
                    </label>
                    <input
                      {...register('first_name', { required: 'required' })}
                      id="about-you_first_name"
                      className="about-you-form-control"
                    ></input>
                    {errors?.first_name && <p className="p3 dome-color-red-alert">{errors.first_name.message}</p>}
                  </div>
                  <div className="about-you-form-group">
                    <label htmlFor="about-you_middle_name" className="about-you-form-label">
                      middle name
                    </label>
                    <input
                      {...register('middle_name')}
                      id="about-you_middle_name"
                      className="about-you-form-control"
                    ></input>
                    {errors?.middle_name && <p className="p3 dome-color-red-alert">{errors.middle_name.message}</p>}
                  </div>
                  <div className="about-you-form-group">
                    <label htmlFor="about-you_last_name" className="about-you-form-label">
                      last name*
                    </label>
                    <input
                      {...register('last_name', { required: 'required' })}
                      id="about-you_last_name"
                      className="about-you-form-control"
                    ></input>
                    {errors?.last_name && <p className="p3 dome-color-red-alert">{errors.last_name.message}</p>}
                  </div>
                </div>
                <div
                  className={clsx(
                    'about-you-form-group-wrapper four-split',
                    insuranceType == 'renters' && 'renters-selected'
                  )}
                >
                  <div className="about-you-form-group">
                    <label htmlFor="about-you_email" className="about-you-form-label">
                      email*
                    </label>
                    <input
                      {...register('email', { required: 'required' })}
                      id="about-you_email"
                      className="about-you-form-control"
                    ></input>
                    {errors?.email && <p className="p3 dome-color-red-alert">{errors.email.message}</p>}
                  </div>
                  <div className="about-you-form-group">
                    <label htmlFor="about-you_phone_number" className="about-you-form-label">
                      phone number*
                    </label>
                    <input
                      {...register('phone_number', {
                        required: 'required',
                        pattern: {
                          value: VALID_PHONE_NUMBER_REGEX,
                          message:
                            'The valid phone number as follow: 205-555-0125, 202 555 0125, 2055550125, 205.555.0125, (202) 555-0125, 123-456-7890 ext9876',
                        },
                      })}
                      id="about-you_phone_number"
                      className="about-you-form-control"
                    ></input>
                    {errors?.phone_number && <p className="p3 dome-color-red-alert">{errors.phone_number.message}</p>}
                  </div>
                  <div className="about-you-form-group">
                    <label htmlFor="about-you_dob" className="about-you-form-label">
                      date of birth*
                    </label>
                    <input
                      type="date"
                      {...register('dob', {
                        required: 'required',
                        validate: {
                          validAge: (value) => {
                            if (isValidAge(value)) return true

                            return 'Below the minimum age 18'
                          },
                        },
                      })}
                      max={format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')}
                      min="1900-01-01"
                      id="about-you_dob"
                      className="about-you-form-control"
                    ></input>
                    {errors?.dob && <p className="p3 dome-color-red-alert">{errors.dob.message}</p>}
                  </div>
                  {insuranceType == 'homeowners' && (
                    <div className="about-you-form-group">
                      <label htmlFor="about-you_insured_type" className="about-you-form-label">
                        insured type*
                      </label>
                      <select
                        {...register('insured_type', { validate: { required: selectRequiredValidation('required') } })}
                        id="about-you_insured_type"
                        className="about-you-form-control"
                        defaultValue=""
                      >
                        <option disabled value="">
                          select insured type
                        </option>
                        {insuredTypes.map(({ key, value }) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          )
                        })}
                      </select>
                      {errors?.insured_type && <p className="p3 dome-color-red-alert">{errors.insured_type.message}</p>}
                    </div>
                  )}
                </div>
              </div>
              <div className="about-you-form-content-item">
                <div className="dome-h4 dome-color-dark-grey">current mailing address</div>
                <div className="about-you-form-group-wrapper">
                  <div className="about-you-form-check">
                    <input
                      type="checkbox"
                      {...register('is_billing_address_same_as_location')}
                      id="about-you_is_billing_address_same_as_location"
                      className="about-you-form-check-input"
                    ></input>
                    <label
                      htmlFor="about-you_is_billing_address_same_as_location"
                      className="about-you-form-check-label dome-p2 dome-user-select-none"
                      role="button"
                    >
                      Current mailing address is same as {buildingStructure?.default_additional_address?.address}
                    </label>
                  </div>
                </div>
                {!isBillingAddressSame && (
                  <>
                    <div className="about-you-form-group-wrapper">
                      <div className="about-you-form-group">
                        <label htmlFor="about-you_billing_address_line1" className="about-you-form-label">
                          address line 1*
                        </label>
                        <input
                          {...register('billing_address_line1', { required: 'required' })}
                          id="about-you_billing_address_line1"
                          className="about-you-form-control"
                        ></input>
                        {errors?.billing_address_line1 && (
                          <p className="p3 dome-color-red-alert">{errors.billing_address_line1.message}</p>
                        )}
                      </div>
                      <div className="about-you-form-group">
                        <label htmlFor="about-you_billing_address_line2" className="about-you-form-label">
                          address line 2
                        </label>
                        <input
                          {...register('billing_address_line2')}
                          id="about-you_billing_address_line2"
                          className="about-you-form-control"
                        ></input>
                        {errors?.billing_address_line2 && (
                          <p className="p3 dome-color-red-alert">{errors.billing_address_line2.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="about-you-form-group-wrapper">
                      <div className="about-you-form-group">
                        <label htmlFor="about-you_billing_city" className="about-you-form-label">
                          city*
                        </label>
                        <input
                          {...register('billing_city', { required: 'required' })}
                          id="about-you_billing_city"
                          className="about-you-form-control"
                        ></input>
                        {errors?.billing_city && (
                          <p className="p3 dome-color-red-alert">{errors.billing_city.message}</p>
                        )}
                      </div>
                      <div className="about-you-form-group">
                        <label htmlFor="about-you_billing_state" className="about-you-form-label">
                          state*
                        </label>
                        <select
                          {...register('billing_state', {
                            validate: { required: selectRequiredValidation('required') },
                          })}
                          id="about-you_billing_state"
                          className="about-you-form-control"
                          defaultValue=""
                        >
                          <option disabled value="">
                            select state
                          </option>
                          {USStates.map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            )
                          })}
                        </select>
                        {errors?.billing_state && (
                          <p className="p3 dome-color-red-alert">{errors.billing_state.message}</p>
                        )}
                      </div>
                      <div className="about-you-form-group">
                        <label htmlFor="about-you_billing_zip" className="about-you-form-label">
                          zip*
                        </label>
                        <input
                          {...register('billing_zip', {
                            required: 'required',
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              message: 'only accept number',
                            },
                          })}
                          inputMode="numeric"
                          id="about-you_billing_zip"
                          className="about-you-form-control"
                        ></input>
                        {errors?.billing_zip && <p className="p3 dome-color-red-alert">{errors.billing_zip.message}</p>}
                      </div>
                      <div className="about-you-form-group">
                        <label htmlFor="about-you_billing_county" className="about-you-form-label">
                          county*
                        </label>
                        <input
                          {...register('billing_county', { required: 'required' })}
                          id="about-you_billing_county"
                          className="about-you-form-control"
                        ></input>
                        {errors?.billing_county && (
                          <p className="p3 dome-color-red-alert">{errors.billing_county.message}</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="about-you-opt-in-section">
              <div className="about-you-form-check">
                <input
                  type="checkbox"
                  {...register('opt_in')}
                  id="about-you_opt_in"
                  className="about-you-form-check-input"
                ></input>
                <label htmlFor="about-you_opt_in" className="dome-p2 dome-user-select-none" role="button">
                  <div dangerouslySetInnerHTML={{ __html: overrides?.agreement.html }} />
                </label>
              </div>
              {overrides?.show_notices.toggle && (
                <>
                  <div className="dome-p2 dome-color-med-grey" style={{ marginTop: '12px', marginBottom: '12px' }}>
                    <div dangerouslySetInnerHTML={{ __html: overrides?.fraud_disclosure.html }} />
                  </div>
                  <div className="dome-p2 dome-color-med-grey">
                    <div dangerouslySetInnerHTML={{ __html: overrides?.notice_at_collection.html }} />
                  </div>
                </>
              )}
            </div>
            {errorDescription ? renderErrorsFromServer() : null}
            <div className="about-you-action-section">
              <button type="button" className="dome-btn dome-btn-link dome-btn-link-back" onClick={goBack}>
                back
              </button>
              <button
                type="submit"
                className={clsx(['dome-btn dome-btn-base', isOptedIn ? 'dome-btn-go-green' : 'dome-btn-disabled'])}
                disabled={!isOptedIn}
              >
                next
              </button>
            </div>
            <div className="viaffinity-disclosure-mobile">
              <div className="dome-p3" style={{ marginBottom: '24px' }}>
                * Estimate based on property’s requirements, insurer’s recommendations, or expected values and is not
                final.
              </div>
              <ViaffinityDisclosureContent legalDisclaimer={overrides?.legal_disclaimer.html} />
            </div>
          </div>
        </form>
      </div>
      <ConsumerDisclosureStatementModal
        id="consumer-disclosure-modal"
        open={consumerDisclosureStatementModalVisibility}
        onClose={onConsumerDisclosureStatementModalClose}
      />
      <FraudDisclosureModal
        id="fraud-disclosure-modal"
        open={fraudDisclosureModalVisibility}
        onClose={onFraudDisclosureModalClose}
      />
    </>
  )
}
